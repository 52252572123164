@import "theme/variables.scss";

.default-card {
  border: 1px solid $--clr-primary-100;
  border-radius: 0.5rem;

  background-color: $--clr-neutral-100;

  padding: 1rem;
}
