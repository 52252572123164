@import "theme/variables.scss";

.career-info-title {
  background-color: $--clr-primary-300;

  border-radius: 0 10px 0 0;

  width: fit-content;

  position: relative;

  padding: 1rem;

  padding-inline-end: 2.5rem;

  padding-block-end: 0;
}

.career-info[dir="rtl"] .career-info-title {
  border-radius: 10px 0 0 0;
}

.career-info[dir="rtl"] .career-info-title:after {
  inset: 0 100% 0 0;

  box-shadow: 14px 0.25em 0 0px $--clr-primary-300;

  border-radius: 0 0 10px 0;
}

.career-info-title:after {
  content: "";

  width: 100%;
  height: 100%;

  box-shadow: -14px 0.25em 0 -4px $--clr-primary-300;

  position: absolute;

  inset: 0 0 0 100%;

  border-radius: 0 0 0 10px;
}

.career-info-container {
  background-color: $--clr-primary-300;

  display: flex;
  gap: 1rem;
  flex-direction: column;

  padding-block-end: 2rem;

  position: relative;
}

.career-info-container::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 50%;

  width: 50px;
  aspect-ratio: 1;

  background-color: $--clr-primary-300;

  clip-path: polygon(50% 50%, 0 0, 100% 0);

  transform: translate(-50%, 50px);
}

@media (min-width: 998px) {
  .career-info-title {
    color: $--clr-primary-300;
    background-color: transparent;
  }

  .career-info-title::after,
  .career-info-container::after {
    display: none;
  }

  .career-info-container {
    background-color: transparent;
  }
}
