.icon-btn {
  background-color: transparent;

  padding: 0;
  margin: 0;

  border: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: min-content;
  height: min-content;
}
