//Loading Robot Font Family

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "./variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

* {
  font-family: "Roboto", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  font: inherit;
}

ul[role*="list"],
ol[role*="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: $--clr-neutral-200;
}

a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@import "./utils.scss";

h1 {
  font-size: 26px;
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
}

h3 {
  font-size: 20px;
  font-weight: bold;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
  font-weight: normal;
}

.react-loading-skeleton {
  line-height: inherit;
}

.swiper-wrapper {
  display: flex;
  min-height: 0;
}

.swiper-pagination {
  margin-top: 1.5rem;
  position: static;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 10px);
  height: 10px;
  width: 10px;
  outline: none;
  background: #777777;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  background: #687cc5;
}

.career-desktop-schedule,
.career-mobile-schedule {
  overflow: hidden;
  position: relative;
  .swiper {
    overflow: visible;
  }
}

.occupations .swiper-slide {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    transition: all 0.4s ease;
  }
}

@media only screen and (max-width: 400px) {
  .occupations .swiper-slide {
    height: 170px;
  }
}