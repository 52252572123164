.modal {
  position: fixed;
  inset: 0;

  width: 100%;
  height: 100%;

  border: 0;

  z-index: 1200;

  background-color: transparent;

  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  padding: 1rem;

  width: calc(100% - 1rem * 2);

  background-color: #fff;

  margin: auto;

  border: 0;
  border-radius: 0.5rem;
}

.modal[aria-modal-size="sm"] .modal-container {
  width: #{"min(600px, 100% - 1rem * 2)"};
}

.modal[aria-modal-size="md"] .modal-container {
  width: #{"min(900px, 100% - 1rem * 2)"};
}

.modal[aria-modal-size="lg"] .modal-container {
  width: #{"min(1200px, 100% - 1rem * 2)"};
}
