.blocks-wrapper[dir="rtl"] {
  margin-inline-start: 0;
}

.blocks-section {
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin-top: 30px;
}
