@import 'theme/variables.scss';

.career-info-content {
  padding-block-start: 1rem;
}

.career-info-content h3,
.career-info-content h1 {
  font-weight: normal;
}

.career-info-content h1 {
  font-size: 3rem;
}

@media (min-width: 998px) {
  .info-card {
    background-color: $--clr-primary-300;

    color: $--clr-neutral-100
  }

  .info-card h1 {
    font-size: 2.5rem;
  }
}