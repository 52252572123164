@import "theme/variables.scss";

.review-card {
  width: clamp(300px, 25vw, 400px);
  height: 100%;

  border-radius: 20px;

  border: 1px solid $--clr-primary-100;

  background-color: $--clr-neutral-100;

  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.review-card[itemType="primary"] {
  width: 100%;
}

.review-card[itemType="secondry"] {
  background-color: $--clr-primary-300;
  border: none;
}

.review-card[itemType="secondry"] :is(.author, .graduate-year, .content) {
  color: $--clr-neutral-100;
}

.review-card[itemType="secondry"] button {
  color: $--clr-neutral-100 !important;
  text-decoration: underline;
}

.career-card-company-mob {
  color: $--clr-neutral-100;
}

.user-info {
  display: grid;
  grid-template-columns: 60px 1fr;
  column-gap: 1.5rem;
}

.user-info img {
  grid-row: span 2;

  align-self: center;

  border-radius: 50%;

  width: 100%;
  aspect-ratio: 1;

  object-fit: cover;
  object-position: center;
}

.author {
  font-size: revert;
}
