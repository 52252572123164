.primary-btn {
  background-color: transparent;

  border-radius: 1rem;

  padding: 1rem;

  color: #fff;

  font-weight: 500;

  font-size: 18px;
}
