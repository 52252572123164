@import "theme/variables.scss";

.header-wrapper {
  padding: 1rem;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;

  z-index: 1200;

  background-color: $--clr-neutral-100;
}

.header-container {
  & > *:not(.primary-navigation):not(.search-input-header) {
    z-index: 10;
  }

  display: grid;
  grid-template-areas:
    "logo logo language-picker nav-toggler"
    "search-input-header search-input-header search-input-header search-input-header";
  grid-template-columns: repeat(4, 1fr);
  row-gap: 30px;
  column-gap: 10px;
}

.logo-link {
  max-width: 200px;

  grid-area: logo;

  display: grid;

  & > * {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
}

.nav-toggler {
  grid-area: nav-toggler;
  justify-self: flex-end;
  align-self: center;
}

.header-logo {
  width: 100%;
}

.primary-navigation {
  display: none;

  position: fixed;
  inset: 0;

  background-color: $--clr-neutral-100;
}

.primary-navigation[data-visible="true"] {
  display: block;
}

.nav-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  height: 100%;
}

.nav-link {
  color: $--clr-neutral-900;
}

.search-input-header {
  grid-area: search-input-header;
}

@media (min-width: 60em) {
  .primary-navigation {
    display: block;

    position: unset;
  }

  .header-container {
    grid-template-areas: none;
    grid-template-columns: 1fr 2fr .5fr 1fr;
    column-gap: 10px;

    & > * {
      grid-area: inherit;
    }
  }

  .search-input-header {
    justify-self: flex-end;
  }

  .nav-list {
    flex-direction: row;
  }

  .nav-toggler {
    display: none;
  }
}
