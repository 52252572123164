@import "theme/variables.scss";

.hashtags {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hashtag {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hashtag p {
  color: $--clr-primary-400;
}
