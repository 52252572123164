@import "theme/variables.scss";

.career-video-title {
  font-size: 18px;
  color: $--clr-primary-300;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.career-video-title__triangle {
  height: 0;
  display: inline-block;
  border-style: solid;
  border-width: 8px 0 8px 14px;
  border-color: transparent transparent transparent #007bff;
  margin-right: 0.75rem;
}

.career-video-title__triangle-rtl {
  transform: rotateY(180deg);
  margin-left: 0.75rem;
}

.career-video-wrapper {
  border-radius: 10px;
  overflow: hidden;
  display: inline-flex;
  max-width: 600px;
  width: 100%;
  height: 400px;
  position: relative;
}

.career-video-mob {
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.career-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

