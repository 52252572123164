@import "theme/variables.scss";

.accordion-list {
  margin-inline: auto;

  border: 1px solid $--clr-primary-100;
  border-radius: 0.5rem;

  background-color: $--clr-neutral-100;
}

.accordion-item {
  padding: 1rem 1.25rem;
}

.accordion-list > :not(.accordion-item:last-child) {
  border-bottom: 1px solid $--clr-primary-100;
}

.accordion-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordion-body {
  margin-block-start: 1rem;

  display: none;
}

.accordion-body[aria-expanded="true"] {
  display: block;
}
