:where(.d-flex) {
  display: flex;
}

:where(.space-between) {
  justify-content: space-between;
}

:where(.align-center) {
  align-items: center;
}

:where(.padding-block-start-900) {
  padding-block-start: 150px;
}

:where(.padding-block-900) {
  padding-block: 150px;
}

:where(.padding-block-start-200) {
  padding-block-start: 50px;
}

:where(.padding-block-200) {
  padding-block: 50px;
}

:where(.container) {
  width: #{"min(1400px, 100%)"};

  padding-inline: 1rem;

  margin-inline: auto;
}

:where(.container-fluid) {
  width: 100%;
  padding-inline: 1rem;
  margin-inline: auto;
}

:where(.container-right-aligned) {
  width: calc(100% - 1rem);
  margin-inline-start: auto;
}

:where(.text-white) {
  color: $--clr-neutral-100;
}

:where(.text-primary-400) {
  color: $--clr-primary-400;
}

:where(.text-primary-300) {
  color: $--clr-primary-300;
}

:where(.text-primary-100) {
  color: $--clr-primary-100;
}

:where(.margin-block-start-200) {
  margin-block-start: 1.25rem;
}

:where(.grid) {
  display: grid;
  grid-template-columns: repeat(var(--cols, 0), 1fr);
  grid-gap: var(--gap, 10px);

  @media (max-width: 998px) {
    display: grid;
    grid-template-columns: repeat(var(--cols-mobile, 0), 1fr);
    grid-gap: var(--gap, 10px);
  }
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.p-1 {
  padding: 1rem;
}

.p-inline-1 {
  padding-inline: 1rem;
}

.p-block-1 {
  padding-block: 1rem;
}

.m-inline-1 {
  margin-inline: 1rem;
}

.title-primary {
  font-weight: 500;

  padding: 1rem 0;
}

.title-secondry {
  color: $--clr-neutral-100;
  font-weight: 500;

  padding: 1rem 0;
}

:where(.text-center) {
  text-align: center;
}

:where([class*="-btn"]) {
  border: none;
  background-color: transparent;
}
