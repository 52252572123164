@import "theme/variables.scss";

.schedule-card-item {
  border-radius: 15px;
  padding: 10px 15px;
  color: $--clr-neutral-100;
  &:not(:last-child) {
    margin-bottom: 3px;
  }
  &:nth-child(1){
    background: $--clr-primary-300;
  }
  &:nth-child(2){
    background: $--clr-neutral-300;
  }
  &:nth-child(3){
    background: $--clr-accent-100;
  }
  &:nth-child(4){
    background: $--clr-accent-200;
  }
  &:nth-child(5){
    background: $--clr-accent-100;
  }
  &:nth-child(6){
    background: $--clr-primary-300;
  }
}

.schedule-card-item__title {
  font-weight: 500;
  font-size: 18px;
}
