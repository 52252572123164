.overview-desktop-wrapper .sides {
  display: grid;
  grid-template-columns: 1fr #{"min(400px, 35vw)"};
  gap: 5vw;
}

.overview-desktop-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.overview-desktop-wrapper .left-side {
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-width: 0;
}

.overview-desktop-wrapper .right-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hashtags-overview {
  display: flex;
  flex-direction: row;
}
