@import "theme/variables.scss";

.occupation-card-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.occupation-card-blue {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  color: $--clr-neutral-100;
  background-color: $--clr-primary-300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 500;
  text-align: center;
}

.occupation-card-orange {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  color: $--clr-neutral-100;
  background-color: $--clr-accent-400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 500;
  text-align: center;
}

.occupation-card {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  color: $--clr-neutral-100;
  background-color: $--clr-primary-100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-weight: 500;
  text-align: center;
}

.occupation-card-with-descr {
  width: 200px;
  height: 200px;
  margin: 10px;
  text-align: left;
  padding: 20px 0;
  font-size: 14px;
}

.occupation-card-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
  text-align: center;
}

.occupation-card-title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.occupation-card-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  &:nth-child(1) {
    margin-bottom: 5px;
  }
}

.occupation-card-icon {
  width: 20px;
  display: flex;
  justify-content: center;
  margin: 0 10px;
}


@media only screen and (max-width: 400px) {
  .occupation-card-with-descr {
    width: 170px;
    height: 170px;
    padding: 10px 0;
  }

  .occupation-card-title {
    margin-bottom: 5px;
    font-size: 18px;
  }
}