.career-desktop-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.career-desktop-schedule {
  width: 500px;
}

.career-desktop-top {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.career-desktop-video {
  width: 50%;
}

.Desktop_career-desktop-schedule__6qG04 .swiper {
  overflow: visible;
}