@import "theme/variables.scss";

.career-mobile-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.career-comments-mobile {
  background: $--clr-primary-400;
  padding-top: 4rem;
  position: relative;
}

.career-comments-mobile:before {
  position: absolute;
  content: "";
  width: 10rem;
  height: 2.5rem;
  background: $--clr-primary-400;
  top: -2.5rem;
  left: 0;
  border-top-right-radius: 10px;
}

.career-comments-mobile:after {
  content: "";
  width: 30px;
  height: 2.5rem;
  box-shadow: -14px 0.25em 0 -4px $--clr-primary-400;
  position: absolute;
  inset: 0 0 0 100%;
  border-radius: 0 0 0 10px;
  top: -2.5rem;
  left: 10rem;
}

.career-video-mobile {
  padding: 1rem;
}

.career-mobile-schedule {
  width: 100%;
  max-width: 600px;
}

.career-mobile-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.career-mobile-apply {
  margin: 4rem 1rem 0rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.career-mobile-apply-text {
  font-weight: 500;
  color: $--clr-neutral-100;
  font-size: 1.2rem;
}

.career-mobile-comments {
  padding: 3rem 0;
  background: $--clr-primary-400;
  position: relative;
  margin-top: 1rem;
}

.career-mobile-comments__tab {
  width: 160px;
  height: 40px;
  background: $--clr-primary-400;
  position: absolute;
  top: -40px;
  left: 0;
  border-radius: 0 10px 0 0;
  &:after {
    position: absolute;
    content: "";
    right: 0;
    width: 30px;
    height: 40px;
    box-shadow: -11px 0.25em 0 -4px $--clr-primary-400;
    inset: 0 0 0 100%;
    border-radius: 0 0 0 10px;
  }
  
  &[dir="rtl"] {
    left: initial;
    right: 0;
    border-radius: 10px 0 0 0;
    &:after {
      inset: 0 100% 0 0;
      box-shadow: 14px 0.45em 0 0px $--clr-primary-400;
      border-radius: 0 0 10px 0;
    }
  }
}


.career-mobile-apply-btn {
  max-width: 500px;
  width: 100%;
}
