.accordion-header-custom {
  display: grid;
  grid-template-columns: #{"max(10vw, 150px) 1fr 30px"};
}

.accordion-header-custom button {
  justify-self: flex-end;
}

.chart-legend-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.chart-legend {
  display: flex;
  align-items: center;
  gap: 5px;

  & > * {
    line-height: 1;
  }
}

.pie-chart-label-text {
  font-size: #{"min(3vw, 16px)"};
}
