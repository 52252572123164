@import "theme/variables.scss";

.occupation-active {
  background: $--clr-accent-400;
}

.occupation-focused {
  background: $--clr-primary-100;
  border-radius: 40px;
  width: 200px;
  height: 200px;
}

.occupation-focused-blue {
  background: $--clr-primary-300;
  border-radius: 40px;
  width: 200px;
  height: 200px;
}

@media only screen and (max-width: 400px) {
  .occupation-focused {
    width: 170px;
    height: 170px;
  }

  .occupation-focused-blue {
    width: 170px;
    height: 170px;
  }
}