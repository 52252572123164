@import "theme/variables.scss";

.search-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $--clr-neutral-100;
  padding: 1rem 1.25rem;
  border: 2px solid $--clr-primary-100;
  border-radius: 20px;
  gap: 20px;
  width: inherit;
}

.search-input-container-rtl {
  flex-direction: row-reverse;
}

.input-icon-rtl {
  transform: scaleX(-1);
}

.search-input {
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
}
