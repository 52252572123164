@import "theme/variables.scss";

.occupations-sub-title,
.occupations-title {
  font-size: 1.2rem;
  color: $--clr-primary-300;
  text-align: center;
  font-weight: 400;
}

.occupations-sub-title {
  margin-block-end: 1rem;
}

.occupations-carousel {
  margin-block-end: 1rem;
}

.occupations-more-wrapper {
  display: flex;
  justify-content: center;
}

.occupations-more-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent $--clr-primary-100 transparent;
}
