@import "theme/variables.scss";

.progress {
  width: 100%;
  height: 10px;

  background-color: $--clr-primary-100;

  border-radius: 100vmax;

  position: relative;
}

.progress::after {
  content: "";

  position: absolute;

  inset: 0;

  width: var(--width, 0);
  height: 100%;

  border-radius: 100vmax;

  background-color: $--clr-primary-300;
}
