@import "theme/variables.scss";

.benefits-card {
  background-color: $--clr-primary-300;

  width: #{"min(140px, 30vw)"};

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 0.5rem;
}

.benefits-card > * {
  transform: translateY(calc(-10px - 0.5rem));
  color: $--clr-neutral-100;
}

.benefits-card-icon {
  background-color: $--clr-primary-300;

  border-radius: 100%;

  padding: 10px 15px;
  padding-block-end: 15px;
}

.benefits-card-title {
  transform: translateY(calc(-15px - 0.5rem));
}

.benefits-card-value {
  font-size: 2rem;
}
