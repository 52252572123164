@import "theme/variables.scss";

.apply-now {
  background-color: $--clr-primary-400;

  padding-block: 4rem;
  padding-inline: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (min-width: 998px) {
  .apply-now {
    background-color: transparent;

    flex-direction: row;

    padding: 0 1rem;

    gap: 3rem;
  }

  .apply-now > * {
    flex: 1;
  }
}
