@import "theme/variables.scss";

.course-list-btns {
  display: flex;
  gap: 10px;
}

.course-list .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 2rem;
}

.course-list .cards-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.course-list .list-card {
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 100vmax;
  padding-inline: 2rem;
}

.course-list .list-card > * {
  color: $--clr-primary-300;
  font-weight: 500;
}

.course-list .list-card > *:first-child {
  flex: 1;
}

.course-list-btn {
  background-color: $--clr-primary-100;
  color: $--clr-primary-300;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
}

.course-list-btn[aria-expanded="true"] {
  background-color: $--clr-primary-300;
  color: $--clr-neutral-100;
}

@media (max-width: 998px) {
  .course-list-btns {
    gap: 6px;
    margin-left: 0.5rem;
  }
  .course-list-btn {
    h2 {
      font-size: 18px;
    }
  }
}

@media (max-width: 400px) {
  .course-list-btn {
    padding: 0.25rem 0.65rem;
  }
  .course-list-btn[dir="rtl"] {
    padding: 0.25rem 0.6rem;
  }
  .course-list .heading h4 {
    font-size: 1rem;
  }
  .course-list .heading h4[dir="rtl"] {
    font-size: 13px;
  }
}
