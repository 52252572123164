.image-card {
  max-width: #{"min(350px, 75vw)"};
  width: 100%;
  aspect-ratio: 1;

  border-radius: 1.25rem;

  overflow: hidden;
}

.image-card > img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: left;
}
