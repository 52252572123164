.academy-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.academy-top-block {
  display: flex;
  justify-content: space-between;
}

.academy-questions {
  width: calc(50% - 30px);
}

.academy-course-list {
  width: calc(50% - 30px);
}