@import "theme/variables.scss";

.overview-mobile-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.overview-hashtags-mobile {
  flex-direction: row;
}

.highlightning-benefits {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.common-stats-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stat-row {
  display: flex;
  align-items: center;
}

.stat-row span {
  font-size: 14px;
  min-width: 160px;
}

.common-stats-progress {
  max-width: 500px;
  margin: auto;
}

.common-stats-count {
  min-width: 45px;
  text-align: right;
  &[dir="rtl"] {
    text-align: left;
  }
}

.accordion-header-custom {
  display: grid;
  grid-template-columns: #{"min(30vw, 150px) 1fr 30px"};
}

.accordion-header-custom button {
  justify-self: flex-end;
}

.technical-skill {
  width: 100%;
  aspect-ratio: 1.5 / 1;
  border: 1px solid $--clr-primary-100;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $--clr-neutral-100;
  overflow: hidden;
  padding: 1rem;
}

.technical-skill img {
  height: 100%;
}

@media (min-width: 998px) {
  .comments {
    overflow: hidden;
  }
}
