@import "theme/variables.scss";

.schedule {
  overflow: hidden;
}

.schedule-title {
  color: $--clr-primary-400;
  margin-bottom: 1.2rem;
  margin-top: 0.2rem;
}

.schedule-top-wrapper {
  display: flex;
  justify-content: space-between;
}

.schedule-top-wrapper-rtl {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 450px) {
  .schedule-title {
    margin-bottom: 5rem;
  }
}