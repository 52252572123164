@import "theme/variables.scss";

.first-screen {
  background-color: rgba(100, 123, 230, 0.9);

  position: relative;
}

.first-screen-container {
  padding-block-start: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 30px;
}

.first-screen .life-choice-title {
  text-transform: uppercase;
  color: $--clr-neutral-100;
  font-size: 3rem;
  line-height: 1.25;
  word-break: break-all;
  hyphens: auto;
}

.first-screen .life-choice-title[dir="rtl"] {
  text-align: right;
}

.cover-image {
  position: absolute;

  inset: 0;

  height: 100%;
  width: 100%;

  object-fit: cover;
  object-position: center;

  z-index: -1;
}

.tabs-list {
  display: flex;

  max-width: #{"min(100vw, 768px)"};

  width: 100%;
}

.tabs-list[dir="rtl"] {
  margin-inline-start: auto;
}

.tabs-list li {
  flex: 1;
  border-bottom: 5px solid $--clr-primary-400;

  padding-block: 0.5rem;

  & > * {
    width: 100%;
  }
}

.tabs-list li[aria-selected="true"],
.tabs-list li:hover {
  border-bottom: 5px solid $--clr-neutral-100;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 15px;
}

.heading-lang-picker {
  justify-content: center;
  border-radius: 1rem;
}

.heading .title {
  font-size: 2rem;
  color: $--clr-primary-400;
  font-weight: normal;
}
