@import "theme/variables.scss";

.language-list {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  flex-basis: 20%;

  grid-area: language-picker;
  justify-self: flex-end;
}

.language-btn {
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.language-btn[aria-checked="true"] {
  border-bottom: 2px solid $--clr-primary-400;
}

.language-btn[aria-checked="true"].secondry {
  border-bottom: 2px solid $--clr-neutral-100;
}
