// Colors

$--clr-primary-400: #1a4e99;
$--clr-primary-300: #5a70e8;
$--clr-primary-200: #5A70E9;
$--clr-primary-100: #dee2fb;

$--clr-neutral-900: #000;
$--clr-neutral-800: #2E2E2E;
$--clr-neutral-300: #c4c4c4;
$--clr-neutral-200: #fcfcfe;
$--clr-neutral-100: #fff;

$--clr-accent-100: #B55DC4;
$--clr-accent-200: #78C37B;
$--clr-accent-400: #f29424;


