@import "theme/variables.scss";

.accordion-item {
  padding: 1rem 1.25rem;
  border: 1px solid $--clr-primary-100;
  border-radius: 0.5rem;
}

.accordion-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordion-body {
  margin-block-start: 1rem;
  display: none;
  p {
    color: $--clr-neutral-800;
    font-weight: 400;
  }
}

.accordion-body[aria-expanded="true"] {
  display: block;
}
