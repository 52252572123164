@import "theme/variables.scss";

.carousel-btns {
  display: flex;
  gap: 25px;
  margin-block-start: 1rem;
}

.week-days-wrapper {
  display: flex;
  position: absolute;
  right: 0;
  top: -3.5rem;
  p {
    color: $--clr-primary-400;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $--clr-primary-100;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    margin-right: 0.5rem;
  }
}

.week-days-wrapper-rtl {
  left: 0;
  right: initial;
}

p.active-btn {
  background: $--clr-primary-200;
  color: $--clr-neutral-100;
}

@media (max-width: 450px) {
  .week-days-wrapper {
    left: 0;
    right: initial;
  }
  .week-days-wrapper-rtl {
    left: initial;
    right: 0;
  }
}
