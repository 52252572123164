@import "theme/variables.scss";

.accordion-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.qna {
  position: relative;
}

.qna .title {
  padding-block-end: 2rem;
}

.qna::after {
  content: "";

  position: absolute;
  bottom: 0;
  left: 50%;

  width: 50px;
  aspect-ratio: 1;

  background-color: $--clr-neutral-100;

  clip-path: polygon(50% 50%, 0 0, 100% 0);

  //translate Y 100px because triangle height 50px + 50px gap between blocks

  transform: translate(-50%, 100px);
}

@media (min-width: 998px) {
    .qna::after {
      display: none;
    }
  }