@import "theme/variables.scss";

.block-card-wrapper,
.block-card-skeleton-wrapper {
  width: clamp(240px, 30vw, 400px);
  aspect-ratio: 1 / 1.5;
  height: 340px;

  position: relative;

  isolation: isolate;

  display: grid;
  grid-template-rows: 100%;

  border-radius: 20px;

  overflow: hidden;
}

.block-card-wrapper[aria-card-size="sm"],
.block-card-skeleton-wrapper[aria-card-size="sm"] {
  width: 240px;
}

.block-card-wrapper > *,
.block-card-skeleton-wrapper > * {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.block-card-wrapper {
  background-color: $--clr-primary-100;
}

.block-card-wrapper:hover {
  .block-card__title {
    backdrop-filter: blur(5px);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}

.block-card__title {
  align-self: flex-end;
  padding: 1rem 0.5rem;

  color: #fff;

  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 65%,
    rgba(0, 0, 0, 1) 100%
  );

  display: flex;
  align-items: flex-end;
  h1{
    font-size: 21px;
  }
}

.block-card-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: right;

  z-index: -1;
}
